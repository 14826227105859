<template>
  <div
    v-if="availableShippingWays.length"
    class="rounded-dotted mt-1 text-text_color text-opacity-80"
  >
    <div class="flex md:justify-around justify-between items-center">
      <div class="md:font-semibold md:text-lg">{{ t('delivery_methods') }}</div>
      <div class="mx-2 mt-1">
        <div
          v-for="(item, index) in availableShippingWays"
          :key="`availableShippingWays-${index}`"
        >
          <div
            class="flex cursor-pointer rounded-dotted light mb-1"
            @click="updateMethodShipping(item)"
          >
            <div class="w-2"></div>
            <input
              v-model="updateShippingWays"
              :disabled="item.disable"
              type="radio"
              :value="item"
            />
            <div class="w-4"></div>
            <div class="mx-1">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        !store.isStoreSupportsPrivateDeliveryWithPrice &&
          modelValue?.type == 'store_delivering_way'
      "
      class="text-center bg-bg_color brightness-95 font-semibold text-sm p-1 border-t border-text_alt_color rounded-lg mt-6"
    >
      {{ props.store.shipping_details }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ShippingWay } from '~/composables/useMenuModel'
const { t } = useI18n()
const props = defineProps<{
  modelValue?: ShippingWay
  branchId?: number
  store: Store
  costItemsStore: {
    originalPrice: number
    discount: number
  }
}>()

const deliveryStorePrivateDetails = computed(() => {
  const minimumPriceNumber = parseFloat(
    props.store.minimum_price_for_zero_delivery_cost || '0'
  )
  let deliveryCostLocal = props.store.isStoreSupportsPrivateDeliveryWithPrice
    ? parseFloat(props.store.delivery_cost || '0')
    : undefined
  const totalPrice =
    props.costItemsStore.originalPrice - props.costItemsStore.discount
  if (
    totalPrice > minimumPriceNumber &&
    props.store.isStoreSupportsPrivateDeliveryWithPrice
  ) {
    deliveryCostLocal = 0
  }
  return {
    deliveryCost: deliveryCostLocal,
    agentPlace: props.modelValue?.agentPlace,
    disable: false,
    type: 'store_delivering_way',
    deliveryTime: props.store.delivery_duration || 0,
    title: t('store_delivering_way', {
      name: props.store.name
    })
  }
})

const updateShippingWays = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    updateMethodShipping(value)
  }
})
function updateMethodShipping(value: ShippingWay | undefined) {
  emit('update:modelValue', value)
}

const emit = defineEmits<{
  (event: 'update:modelValue', value?: ShippingWay): void
}>()
const availableShippingWays = computed(() => {
  const availableShippingWaysTemp = ref<ShippingWay[]>([])

  if (props.store.support_delivery) {
    availableShippingWaysTemp.value.push(deliveryStorePrivateDetails.value)
  }
  availableShippingWaysTemp.value.push({
    deliveryCost: undefined,
    deliveryTime: undefined,
    type: 'store_pickup',
    title: t('store_pickup'),
    agentPlace: undefined
  })

  return availableShippingWaysTemp.value
})

onMounted(() => {
  if (availableShippingWays.value?.length) {
    const ShippingWayTemp = availableShippingWays.value[0]
    emit('update:modelValue', ShippingWayTemp)
  }
})
</script>
