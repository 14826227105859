<template>
  <div v-if="badgeCount">
    <div v-if="offers">
      <RikazModalDialog overlay="bg-black opacity-95">
        <template #trigger="{ open }">
          <div
            v-for="(store, id) in itemsByStoreSortedByOffer"
            :key="`cart-store-${id}`"
            :data-theme="
              store.store.typeIsDelivering ? 'delivering' : 'shopping'
            "
          >
            <CartItemsByStoreWithCost
              :offers="offers"
              :is-cart-has-one-stores="itemsByStoreSortedByOffer?.length === 1"
              :store-with-items="store"
              @open-dialog=";[(dataDialog = $event), open()]"
            />
          </div>
        </template>

        <template #content="{ close }">
          <div class="text-white">
            <HomeOffersGroupSlide
              v-if="dataDialog?.offer"
              class="mx-auto"
              style="width: 80vw"
              :offer="dataDialog?.offer"
            />
            <CartDeleteMaterialsByStore
              v-else-if="dataDialog?.store?.store"
              :store="dataDialog?.store.store"
              :close-dialog="close"
            />
          </div>
        </template>
      </RikazModalDialog>
    </div>
  </div>
  <div v-else class="flex flex-col items-center justify-center mt-20">
    <IconsEmptyCart class="w-20 h-20 sm:w-28 h-28" />

    <p class="mt-10 mb-5">{{ t('empty_cart') }}</p>

    <MevcutLink
      to="/"
      class="bg-button_color text-text_alt_color rounded py-1 px-2"
      :aria-label="t('aria_page_label', { page: t('home') })"
    >
      {{ t('browse', { page: t('materials') }) }}
    </MevcutLink>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { GroupOffer } from '~~/composables/useMenuModel'

const props = defineProps<{
  storeIdFilter?: number
}>()

const cart = useCartStore()
const offers = ref<GroupOffer[] | null>(null)
const dataDialog = ref<any>(null)
const itemsByStoreSortedByOffer = computed(() => {
  if (props.storeIdFilter) {
    return cart.itemsByStoreSortedByOffer.filter(
      (itemStore) => itemStore.id === props.storeIdFilter
    )
  }
  return cart.itemsByStoreSortedByOffer
})
const badgeCount = computed(() => cart.badgeCount)
const { t } = useI18n()

async function fetchOffers() {
  const materialIds = itemsByStoreSortedByOffer.value?.reduce(
    (total: number[], item) => {
      if (item.items?.withOutOffers?.length) {
        item.items?.withOutOffers.forEach((cartItem: CartItem) => {
          if (cartItem.material_id) {
            total.push(cartItem.material_id)
          }
        })
      }

      return total
    },
    []
  )
  if (materialIds.length) {
    await useBasicFetch(
      getAllRoutes().materialsRoutes.materialGroupOfferByServer,
      {
        query: { 'material_ids[]': materialIds },
        transform: (data: SuccessResponse<GroupOffer[]>) => {
          const { groupOfferModel } = useMenuModel()
          data.data.map(groupOfferModel)
          offers.value = data.data
        }
      }
    )
  } else {
    offers.value = []
  }
}

await fetchOffers()
</script>
