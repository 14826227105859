<template>
  <div class="relative">
    <CartCostCartPerStoreCost
      class="hidden md:block"
      :store="store"
      :shipping-way="selectedShippingWay"
      :total-price="totalPrice"
      :currency-code="currency.code"
      :table-price-added="tablePriceAdded"
      :count-cost-items="countCostItems"
    />
    <CartCostCartPerStoreShippingWays
      v-if="!table?.table_number"
      :key="`CartShippingWays-${store.id}`"
      :store="store"
      :branch="branch"
      :cost-items-store="countCostItems"
      :model-value="selectedShippingWay"
      @update:model-value="
        ;[emit('select-shipping-way', $event), (selectedShippingWay = $event)]
      "
    />
    <CartCostCartPerStorePayWays
      v-model="selectedPayWays"
      class="mt-1"
      :store="store"
      :branch="branch"
      :shipping-way="selectedShippingWay"
      :total-amount="useStringPrice(totalPrice, currency.code)"
    />
    <CartCostCartPerStoreCropperAttachementPay
      v-if="!isDomain"
      v-model="paymentImage"
      :payment-office="selectedPayWays.payWayCard?.name"
      class="mt-1"
    />
    <CartCostCartPerStoreCost
      class="md:hidden"
      :store="store"
      :shipping-way="selectedShippingWay"
      :total-price="totalPrice"
      :currency-code="currency.code"
      :table-price-added="tablePriceAdded"
      :count-cost-items="countCostItems"
    />

    <CartCostCartPerStoreBuyCart
      :store="store"
      :selected-shipping-way="selectedShippingWay"
      :branch-id="branch?.id"
      :items-with-out-offers="itemsWithOutOffers"
      :payment-image="paymentImage"
      :total-price="totalPrice"
      :table-price-added="tablePriceAdded"
      :all-offers-with-items="allOffersWithItems"
      :count-cost-items="countCostItems"
      :currency="currency"
      :items-with-offers="itemsWithOffers"
      :selected-pay-ways="selectedPayWays"
      :table="table"
      @open-dialog="openDialog"
    />
  </div>
</template>

<script setup lang="ts">
import type {
  Store,
  CartItem,
  payWay
} from '~~/composables/useMenuModel'
const props = defineProps<{
  itemsWithOutOffers: CartItem[]
  itemsWithOffers: object
  store: Store
  modelValue?: string
  table?: Table
  branch?: BranchStore
}>()
const isDomain = useIsDomain()
const emit = defineEmits([
  'open-dialog',
  'select-shipping-way',
  'update:modelValue'
])

function openDialog(store?: Store) {
  emit('open-dialog', { store })
}
const paymentImage = ref<any>(undefined)
const selectedPayWays = ref<{
  payType?: string
  payWayCard?: payWay
}>({
  payType: ''
})
const selectedShippingWay = ref<ShippingWay | undefined>(undefined)

const allOffersWithItems = computed(() => {
  return Object.values(props.itemsWithOffers)
})

const currency = computed(() => {
  const itemsOffers = Object.values(props.itemsWithOffers)
  if (props.itemsWithOutOffers.length) {
    return {
      code: props.itemsWithOutOffers[0].material.currencyCode,
      currencyIsRtl: props.itemsWithOutOffers[0].material.currencyIsRtl
    }
  } else if (itemsOffers.length) {
    return {
      code: itemsOffers[0][0].material.currencyCode,
      currencyIsRtl: itemsOffers[0][0].material.currencyIsRtl
    }
  } else {
    return {
      code: null,
      currencyIsRtl: null
    }
  }
})

const countCostItems = computed(() => {
  const costItemsWithOutOffers = countCostItemsWithOutOffers()
  const costItemsWithOffers = countCostItemsOffers()

  return {
    originalPrice:
      costItemsWithOutOffers.originalPrice + costItemsWithOffers.originalPrice,
    discount:
      costItemsWithOutOffers.discountPrice + costItemsWithOffers.discountPrice
  }
})

const totalPrice = computed(() => {
  return (
    countCostItems.value.originalPrice -
    countCostItems.value.discount +
    (selectedShippingWay.value?.deliveryCost || 0) +
    tablePriceAdded.value
  )
})
const tablePriceAdded = computed(() => {
  if (props.table?.table_number) {
    if (
      props.store.is_fixed_table_addition &&
      props.store.table_fixed_addition
    ) {
      return props.store.table_fixed_addition
    } else if (props.store.table_percentage_addition) {
      const originalPrice =
        countCostItems.value.originalPrice - countCostItems.value.discount
      return (props.store.table_percentage_addition / 100) * originalPrice
    }
  }
  return 0
})
function countCostItemsWithOutOffers() {
  let cost = { originalPrice: 0, offerPrice: 0, discountPrice: 0 }
  cost = props.itemsWithOutOffers.reduce(
    (total, item) => {
      total.originalPrice += item.fullOrginalPrice!
      if (item.material.offers?.length) {
        total.offerPrice += item.fullOfferPrice!
        total.discountPrice += item.fullOrginalPrice! - item.fullOfferPrice!
      }
      return total
    },
    {
      originalPrice: 0,
      offerPrice: 0,
      discountPrice: 0
    }
  )

  return cost
}

function countCostItemsOffers() {
  const cost = {
    originalPrice: 0,
    offerPrice: 0,
    discountPrice: 0
  }

  if (allOffersWithItems.value.length) {
    allOffersWithItems.value.forEach((itemsPerOffer: CartItem[]) => {
      if (itemsPerOffer.length) {
        cost.offerPrice +=
          itemsPerOffer[0].material_group_offer?.priceNumber ?? 0
        cost.originalPrice += itemsPerOffer.reduce((total, item) => {
          cost.offerPrice += item.addonPrice!
          return total + item.fullOrginalPrice!
        }, 0)
      }
    })
  }
  cost.discountPrice = cost.originalPrice - cost.offerPrice
  return cost
}

watch(
  () => totalPrice.value,
  (value) => {
    emit('update:modelValue', useStringPrice(value, currency.value.code))
  }
)
if (isDomain) {
  emit('update:modelValue', useStringPrice(totalPrice.value, currency.value.code))
}

</script>
.
<style>
.rounded-dotted {
  @apply p-2 border-2 rounded-lg bg-bg_color border-dotted border-text_color border-opacity-30;
}
.rounded-dotted.light {
  @apply border-opacity-20 px-px py-1;
}
.rounded-dotted.dark {
  @apply border-opacity-80;
}
</style>
