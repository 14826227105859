<template>
  <CartCostCartPerStorePayWaysPublic
    v-model="updateModelValue"
    class="mt-1"
    :store="store"
    :branch="branch"
    :shipping-way="shippingWay"
    :pay-ways-card="payWaysCard"
    :available-pay-ways="availablePayWays"
    :total-amount="totalAmount"
  />
</template>

<script setup lang="ts">
import type { payWay, Store } from '~/composables/useMenuModel'
import { usePlacesStore } from '~/store/places'
const { t } = useI18n()
const props = defineProps<{
  modelValue: {
    payType?: String
    payWayCard?: payWay
  }
  store: Store
  branch?: BranchStore
  shippingWay?: ShippingWay
  totalAmount: String
}>()
const updateModelValue = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  }
})

onMounted(() => {
  emit('update:modelValue', {
    payType: t('pay_on_door')
  })
})

const { getCityByCityId } = usePlacesStore()
const emit = defineEmits<{
  (
    event: 'update:modelValue',
    value: {
      payType?: String
      payWayCard?: payWay
    }
  ): void
}>()
const payWaysCard = computed(() => {
  const payWayCardTemp = (getCityByCityId(
    props.branch?.city_id ?? props.store.city_id
  )?.payment_ways || []) as payWay[]
  return payWayCardTemp.filter(
    (el: payWay) => {
      const currency = el.currency_id || el.currency
      return currency?.toString() === props.store.display_currency?.id.toString()
    }
  )
})
const availablePayWays = computed(() => {
  const availablePayWaysTemp = [t('pay_on_door')] as String[]

  if (
    payWaysCard.value.length &&
    props.shippingWay?.deliveryCost !== undefined && props.shippingWay?.deliveryCost !== null
  ) {
    availablePayWaysTemp.push(t('pay_by_transfer'))
  }
  return availablePayWaysTemp
})
</script>
