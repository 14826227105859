<template>
  <CartCostCartPerStorePayWaysDomain
    v-if="isDomain"
    v-model="updateModelValue"
    class="mt-1"
    :store="store"
    :branch="branch"
    :shipping-way="shippingWay"
    :total-amount="totalAmount"
  />
  <CartCostCartPerStorePayWaysPlatForm
    v-else-if="shippingWay?.agentPlace?.id"
    v-model="updateModelValue"
    class="mt-1"
    :store="store"
    :branch="branch"
    :shipping-way="shippingWay"
    :total-amount="totalAmount"
  />
</template>

<script setup lang="ts">
import type { payWay, Store } from '~/composables/useMenuModel'
const props = defineProps<{
  modelValue: {
    payType?: String
    payWayCard?: payWay
  }
  store: Store
  branch?: BranchStore
  shippingWay?: ShippingWay
  totalAmount: String
}>()
const { t } = useI18n()
const isDomain = useIsDomain()
const updateModelValue = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  }
})
watch(
  () => props.shippingWay?.deliveryCost,
  (value) => {
    if (!isDomain && !value) {
      emit('update:modelValue', {
        payType: t('pay_on_door')
      })
    }
  }
)
const emit = defineEmits<{
  (
    event: 'update:modelValue',
    value: {
      payType?: String
      payWayCard?: payWay
    }
  ): void
}>()
</script>
