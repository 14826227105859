<template>
  <CartCostCartPerStoreShippingWaysDomain
    v-if="isDomain"
    v-model="updateModelValue"
    class="mt-1"
    :store="store"
    :branch-id="branch?.id"
    :cost-items-store="costItemsStore"
  />
  <CartCostCartPerStoreShippingWaysPlatForm
    v-else
    v-model="updateModelValue"
    :store="store"
    :branch="branch"
    :cost-items-store="costItemsStore"
  />
</template>

<script setup lang="ts">
import type { Store } from '~/composables/useMenuModel'

const props = defineProps<{
  modelValue?: ShippingWay
  branch?: BranchStore
  store: Store
  costItemsStore: {
    originalPrice: number
    discount: number
  }
}>()

const isDomain = useIsDomain()
const updateModelValue = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  }
})
const emit = defineEmits<{
  (event: 'update:modelValue', value?: ShippingWay): void
}>()

</script>
