<template>
  <div
    class="bg-bg_color brightness-100 text-text_color border border-text-200 rounded-t-lg my-1 pt-2 relative"
  >
    <div
      v-if="loading"
      class="absolute inset-0 bg-button_color bg-opacity-40 rounded-lg"
    >
      <MevcutLoadingIndicator
        :loading="true"
        color="border-nav_color"
        class="text-nav_color h-full"
      />
    </div>
    <CartNormalItemMaterialCard
      :cart-item="item"
      @loading="loading = $event"
    >
      <CartNormalItemQuantity
        :cart-item="item"
        class="mr-auto"
        @increase="editItemLocal('+')"
        @decrease="editItemLocal('-')"
      />
    </CartNormalItemMaterialCard>

    <div v-if="offersFiltered?.length" class="mt-3 text-xs md:text-sm">
      <div
        v-for="(offer, key) in offersFiltered"
        :key="`offer-Items-${key}`"
        class="flex flex-wrap justify-center"
      >
        <div>
          {{
            t('offer_available_for_material', { number: offer.offer_number })
          }}
        </div>
        <div class="w-6"></div>
        <div
          class="text-nav_color hover:underline cursor-pointer"
          @click="emit('open-dialog', { offer: offer })"
        >
          {{ t('view_more') }}
        </div>
      </div>
    </div>
    <div
      class="flex bg-bg_color brightness-95 items-center justify-between px-3 py-2 text-xs sm:text-sm mt-1 border-t border-text_alt_color"
    >
      <button
        class="max bg-nav text-nav_color"
        :aria-label="t('delete_item', { name: t('item') })"
        @click="deleteItemLocal"
      >
        <div class="flex items-center">
          <v-icon icon="mdi mdi-trash-can-outline" class="text-sm" :size="20" />
          <div class="px-1">
            {{ t('delete_item', { name: t('material') }) }}
          </div>
        </div>
      </button>
      <div class="flex">
        <div class="font-semibold">{{ t('full_price') }}:</div>
        <div>
          {{ item.fullOfferPriceString || item.fullOrginalPriceString }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type {
  CartItem,
  GroupOffer,
  Material
} from '~~/composables/useMenuModel'
const toast = useToast()
const props = defineProps<{ item: CartItem; offers: GroupOffer[] }>()
const emit = defineEmits(['open-dialog'])
const { editItem, deleteItem } = useCartStore()
const loading = ref(false)
const { t } = useI18n()

const offersFiltered = computed(() => {
  return props.offers?.reduce((total, itemOffer: GroupOffer) => {
    if (itemOffer.materials?.length) {
      itemOffer.materials?.forEach((material: Material) => {
        if (material.id === props.item.material_id) {
          total.push(itemOffer)
        }
      })
    }

    return total
  }, [] as GroupOffer[])
})
const isDomain = useIsDomain()
async function editItemLocal(operation: string) {
  let enableEdit = true
  if (operation === '-' && (props.item.material.min_quantity || 0) > 0) {
    const newQuantityValue =
      props.item.quantity - (props.item.material.step || 1)
    enableEdit = newQuantityValue >= (props.item.material.min_quantity || 0)
  }
  if (enableEdit) {
    if (!loading.value) {
      loading.value = true
      if (
        await editItem(
          props.item.material,
          props.item.id!,
          props.item.branch_id,
          operation,
          props.item.material.step || 1
        )
      ) {
        toast.success(t('update_done'), { timeout: 1000 })
      } else {
        toast.error(t('update_failed'), { timeout: 1000 })
      }
      loading.value = false
    }
  } else {
    toast.error(
      t('error_min_quantity', {
        name: props.item.material.name,
        quantity: `${props.item.material.min_quantity} ${
          props.item.material.unit || ''
        }`
      }),
      { timeout: 1700 }
    )
  }
}

async function deleteItemLocal() {
  if (!loading.value) {
    loading.value = true
    if (await deleteItem(props.item.id!)) {
      toast.success(t('delete_done'), { timeout: 1000 })
    } else {
      toast.error(t('delete_failed'), { timeout: 1000 })
    }
    loading.value = false
  }
}
</script>

<style>
.hide-scroll {
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}
</style>
