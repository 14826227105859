<template>
  <div>
    <div v-if="cartItem.quantityByUnit" class="flex flex-col justify-center bg-nav text-center large mx-1 text-sm">
      {{ cartItem.quantityByUnit }}
    </div>
    <div v-else class="h-28 flex flex-col items-center justify-between">
      <button
        :aria-label="t('increase_item', { name: t('quantity') })"
        class="w-6 h-6 bg-button_color text-text_alt_color p-0.5 ltr:rounded-l-lg rtl:rounded-r-lg"
        @click="emit('increase')"
      >
        <v-icon icon="mdi mdi-plus-thick" :size="20" />
      </button>

      <div class="bg-nav leading-none text-center">
        {{ cartItem.quantity }} {{ cartItem.material.unit || '' }}
      </div>

      <button
        :aria-label="t('decrease_item', { name: t('quantity') })"
        class="w-6 h-6 bg-button_color text-text_alt_color p-0.5 ltr:rounded-l-lg rtl:rounded-r-lg"
        @click="emit('decrease')"
      >
        <v-icon icon="mdi mdi-minus-thick" :size="20" />
      </button>
      <div class="h-3"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  cartItem: CartItem
}>()

const emit = defineEmits(['increase', 'decrease'])

const { t } = useI18n()
</script>
