<template>
  <div>
    <div class="text-text_alt_color text-lg sm:text-xl text-center">
      <div>
        {{
          t('you_have_clicked', {
            button: t('apply_by', { by: t('whatsapp') })
          })
        }}
      </div>
      <div class="mt-2">{{ t('clear_cart_?', { store: store.name }) }}</div>
    </div>
    <div class="flex justify-around mt-6">
      <div class="w-28">
        <MevcutSubmitButton
          :title="t('yes')"
          type="button"
          :loading="false"
          @click="deleteMaterialsFromCart()"
        />
      </div>
      <div class="w-28 mx-1">
        <MevcutSubmitButton
          :title="t('no')"
          type="button"
          :loading="false"
          @click="closeDialog"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~~/composables/useMenuModel'

const props = defineProps<{
  closeDialog: any
  store: Store
}>()

const cart = useCartStore()
const { t } = useI18n()

async function deleteMaterialsFromCart() {
  await cart.removeMaterialsStoreCart(props.store.id)
  props.closeDialog()
}
</script>
