<template>
  <div
    v-if="store"
    id="cartPage"
    class="border-2 z-3 bg-bg_color rounded-t-xl border-mevcut-green/80 my-5"
  >
    <div class="bg-bg_color rounded-t-xl brightness-95   relative">
      <MevcutLink
        :to="storeRoute(store)"
        :branch-id="storeWithItems.branchId"
        :aria-label="t('aria_page_label', { page: store.name })"
      >
        <div class="flex border-b-2 border-text-200 items-center p-3">
          <NuxtImg
            provider="cacheIpx"
            preset="modified"
            loading="lazy"
            :src="store.icon"
            :alt="store.icon"
            width="86"
            height="86"
            class="object-cover object-center w-[86px] h-[86px] rounded-full mx-2"
          />

          <div class="mx-1">
            <div
              class="sm:text-lg md:text-xl font-semibold text-mevcut-light truncate"
            >
              {{ store.name }}
              {{
                storeWithItems.branch?.name
                  ? ' - ' + storeWithItems.branch?.name
                  : ''
              }}
            </div>
            <div class="md:flex md:flex-wrap text-text_color md:font-medium text-sm md:text-base">
              <div>
                {{ t('number_of_materials') }}:
                {{
                  cart.quantityItemsPerStore(store.id, storeWithItems.branchId)
                }}
              </div>
              <div class="w-3"></div>
              <div v-if="totalPrice">{{ t('total') }}: {{ totalPrice }}</div>
            </div>
          </div>
        </div>
      </MevcutLink>
      <CartTable
        :store="store"
        :branch-id="storeWithItems.branchId"
        :table="table"
      />
    </div>
    <div class="bg-bg_alt_color">
      <div class="p-2 font-semi pt-3">
        <v-expansion-panels v-model="expansionPanelValue">
          <v-expansion-panel>
            <v-expansion-panel-title>{{ t('orders_details') }} </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="bg-bg_alt_color p-2">
                <div class="flex flex-wrap-reverse">
                  <div
                    class="order-2 w-full md:w-7/12 md:order-1 md:rtl:pr-2 md:ltr:pl-2"
                  >
                    <CartItemOffer
                      v-for="(itemsOffer, key) in itemsWithOffers"
                      :key="`cart-item-offer-${key}`"
                      :offer-items="itemsOffer"
                      :branch-id="storeWithItems.branchId"
                      :offer-number="key"
                    />

                    <div v-if="itemsWithOutOffers.length">
                      <CartNormalItem
                        v-for="item in itemsWithOutOffers"
                        :key="`cart-item-${item.material.id}`"
                        :item="item"
                        :offers="offers"
                        @open-dialog="emit('open-dialog', $event)"
                      />
                    </div>
                  </div>

                  <div class="order-1 w-full md:w-5/12 md:order-2">
                    <CartCostCartPerStore
                      v-model="totalPrice"
                      class="mb-2 mt-1"
                      :items-with-out-offers="itemsWithOutOffers"
                      :items-with-offers="itemsWithOffers"
                      :branch="storeWithItems.branch"
                      :store="store"
                      :table="table"
                      @select-shipping-way="selectedShippingWay = $event"
                      @open-dialog="emit('open-dialog', $event)"
                    />
                  </div>
                </div>
                <div
                  v-if="store.cart_hint"
                  class="font-medium text-mevcut-light mt-4 flex items-start"
                >
                  <v-icon
                    icon="mdi mdi-information-variant-circle-outline"
                    :size="20"
                    class="mx-1 flex-shrink-0"
                  />
                  <div class="break-all">{{ store.cart_hint }}</div>
                </div>
                <div class="p-1 sm:w-max">
                  <CartErrors :store-id="store.id" />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  GroupOffer,
  ItemStoreCart
} from '~~/composables/useMenuModel'

const props = defineProps<{
  storeWithItems: ItemStoreCart
  isCartHasOneStores: boolean
  offers: GroupOffer[]
}>()
const selectedShippingWay = ref<ShippingWay | null>(null)
const expansionPanelValue = ref<number | undefined>(
  props.isCartHasOneStores ? 0 : undefined
)
const table = computed(() => {
  return cart.getTableByID(props.storeWithItems.id)
})
const totalPrice = ref<string | undefined>(undefined)
const emit = defineEmits(['open-dialog'])

const { storeRoute } = useDomainState()
const cart = useCartStore()
const { t } = useI18n()

const store = computed(() => {
  return props.storeWithItems.store
})

const itemsWithOutOffers = computed(() => {
  return props.storeWithItems?.items?.withOutOffers || []
})

const itemsWithOffers = computed(() => {
  return props.storeWithItems?.items?.withOffers || []
})
</script>
.
<style>
.v-expansion-panel-text__wrapper {
  padding: 4px 4px 4px !important;
  flex: 1 1 auto;
  max-width: 100%;
}
</style>
