<template>
  <div class="rounded-dotted mt-1 text-text_color text-opacity-80">
    <CartCostCartPerStoreAgentPlaces
      :selected-agent-place="modelValue?.agentPlace"
      :agent-places="places"
      :initail-city-id="store.typeIsDelivering ? store.city_id : undefined"
      @select-agent-place="updateAgentPlace($event)"
    />
    <div
      v-if="shippingCostWithTime?.message?.trim().length"
      class="text-[11px] text-center leading-5 mt-3 bg-bg_color rounded-lg"
    >
      {{ shippingCostWithTime?.message?.trim() }}
    </div>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { ShippingWay, AgentPlace } from '~/composables/useMenuModel'
import { usePlacesStore } from '~/store/places'
const { t } = useI18n()
const props = defineProps<{
  modelValue?: ShippingWay
  branch?: BranchStore
  store: Store
  costItemsStore: {
    originalPrice: number
    discount: number
  }
}>()

const shippingCostWithTime = ref<
  | {
      cost: number
      time: number
      message?: string
    }
  | undefined
>(undefined)
const { getPlaces } = usePlacesStore()
function updateMethodShipping(value: ShippingWay | undefined) {
  emit('update:modelValue', value)
}
const emit = defineEmits<{
  (event: 'update:modelValue', value?: ShippingWay): void
}>()

onMounted(() => {
  nextTick(async () => {
    if (places.value?.length) {
      await updateAgentPlace(places.value[0])
    }
  })
})

const places = computed(() => {
  if (getPlaces.length) {
    if (props.store.typeIsDelivering) {
      return getPlaces?.filter((el) => el.city_id === (props.branch?.city_id ?? props.store.city_id))
    }
    return getPlaces
  }
  return []
})
async function updateAgentPlace(agentPlace: AgentPlace, forceUpdate?: boolean) {
  if (agentPlace.id !== props.modelValue?.agentPlace?.id || forceUpdate) {
    shippingCostWithTime.value = await getShippingCostWithTime(agentPlace)

    updateMethodShipping({
      deliveryCost: shippingCostWithTime.value?.cost,
      deliveryTime: shippingCostWithTime.value?.time,
      message: shippingCostWithTime.value?.message,
      type: '',
      title: '',
      agentPlace
    })
  }
}
watch(
  () => props.costItemsStore.originalPrice,
  async () => {
    if (props.modelValue?.agentPlace?.id) {
      await updateAgentPlace(props.modelValue!.agentPlace!, true)
    }
  }
)
async function getShippingCostWithTime(agentPlace: AgentPlace) {
  const { data } = await useBasicFetch<
    ApiResponse<{
      cost: number
      time: number
      message?: string
    }>
  >(getAllRoutes().cartRoutes.getShippingCostWithTimeByServer, {
    method: 'GET',
    query: {
      storeId: props.store.id,
      branchId: props.branch?.id,
      agentPlaceId: agentPlace.id
    }
  })
  return data.value?.data
}
</script>
