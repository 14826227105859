<template>
  <div>
    <CartCostCartPerStorePayWaysPublic
      v-model="updateModelValue"
      class="mt-1"
      :store="store"
      :branch="branch"
      :shipping-way="shippingWay"
      :pay-ways-card="payWaysCard"
      :available-pay-ways="availablePayWays"
      :total-amount="totalAmount"
    />
  </div>
</template>

<script setup lang="ts">
import type { payWay, Store } from '~/composables/useMenuModel'

const { t } = useI18n()
const props = defineProps<{
  modelValue: {
    payType?: String
    payWayCard?: payWay
  }
  store: Store
  branch?: BranchStore
  shippingWay?: ShippingWay
  totalAmount: String
}>()

const payWaysCard = computed(() => {
  return (props.store?.pay_ways || []) as payWay[]
})

const availablePayWays = computed(() => {
  const availablePayWaysTemp = [] as String[]
  if (props.store?.pay_on_door) {
    availablePayWaysTemp.push(t('pay_on_door'))
  }
  if (payWaysCard.value.length) {
    availablePayWaysTemp.push(t('pay_by_transfer'))
  }
  return availablePayWaysTemp
})
const updateModelValue = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  }
})

const emit = defineEmits<{
  (
    event: 'update:modelValue',
    value: {
      payType?: String
      payWayCard?: payWay
    }
  ): void
}>()
onMounted(() => {
  intialValues()
})

function intialValues() {
  if (availablePayWays.value.length === 1) {
    emit('update:modelValue', {
      payType: availablePayWays.value[0]
    })
    if (
      availablePayWays.value[0] === t('pay_by_transfer') &&
      payWaysCard.value.length === 1
    ) {
      emit('update:modelValue', {
        payType: availablePayWays.value[0],
        payWayCard: payWaysCard.value[0]
      })
    }
  }
}
</script>
